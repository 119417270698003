export default {
  color: 'var(--base-brown)',
  gridColumnMobile: 'repeat(2, 1fr)',
  creditorsColumnsGrid: 'repeat(6, 1fr)',
  borderRadius: '28px',
  creditorsColumns: 6,
  borderWidth: '2px',
  borderColor: 'var(--black)',
  margin: 'auto',
  imageWidth: '100%',
  buttonBackgroundColor: 'var(--transparent)',
  borderStyle: 'none',
  textDecExpandBut: 'underline',
  displayExpButton: 'flex',
  flexDirectExpBut: 'column-reverse',
  justContExpBut: 'center',
  alignItExpBut: 'center',
  iconColor: 'var(--black)',
  expandButtonFont: '0.9rem',
  cardPadding: '2.5rem',
};
