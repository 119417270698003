const createConfig = (acf, style) => (
  {
    general: {
      wrapperPosition: 'relative',
      hero: {
        backgroundColor: acf.sectionSettings?.backgroundColor[0],
      },
    },
    columns: [
      {
        flexSize: '20%',
        components: [
          {
            // desktop StepByStep
            component: 'StepByStepGuideBlock',
            acf: { ...acf.guideSteps },
            props: {
              showComponent: 'return evaluationValues.desktop', // if screen size is from 80 rem
            },
          },
        ],
      },
      {
        components: [
          {
            component: 'FormBlock',
            acf: { ...acf.form },
            props: {
              color: style.formBlockColor,
            },
          },
          {
            // mobile StepByStep
            component: 'StepByStepGuideBlock',
            acf: { ...acf.guideSteps },
            props: {
              showComponent: 'return evaluationValues.mobile', // if screen size is from 80 rem
            },
          },
          {
            // desktop USP
            component: 'USPBlock',
            acf: { ...acf?.stepper },
            props: {
              showComponent: 'return evaluationValues.desktop',
            },
          },
          {
            // mobile USP
            component: 'USPBlock',
            acf: { ...acf?.stepper },
            props: {
              showComponent: 'return evaluationValues.mobile',
            },
          },
          {
            component: 'MobileForeground',
            props: {
              src: '',
              showComponent: 'return evaluationValues.mobile',
            },
          },
        ],
      },
    ],
  }
);

export default createConfig;
