export default {
  borderRadius: '1rem',
  fontWeight: '600',
  textFontWeight: '400',
  cardBackground: '#ffd4b3',
  textColor: 'var(--base-brown)',
  linkCardArrowColor: 'var(--mnc)',
  linkArrowSize: '1.5',
  cardTitleColor: 'var(--base-brown)',
};
