export default {
  transformCustomer: 'rotate(-7deg)',
  wrapperBorderRadius: '1rem',
  marginBottomWrapper: '2.3rem !important',
  imageBorderRadius: '5px 5px 0 0',
  quoteFontSizeP: 'var(--text-font-size)',
  quoteTextAlignP: 'left',
  fontSize: '1.2rem',
  paddingName: '10px 15px',
  textShadowName: '0 0 0 rgb(0 0 0 / 55%), 0 0 15px rgb(0 0 0 / 15%)',
  borderName: '1px solid #f9f9f9',
  boxShadowName: 'none',
  heightImg: '60%',
  wrapperWidth: '100%',
  nameWidthMobile: '80%',
  namePosition: 'absolute',
  imageHeight: '200px',
};
