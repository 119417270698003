export default {
  fill: '%23df202d',
  uspJustifyContent: 'space-between',
  uspMargin: '1rem 0',
  marginLeft: '0',
  mobileUspMargin: '0 auto',
  uspPadding: '1rem 0.5rem',
  backgroundColor: 'rgba(0, 198, 0, 0.6)',
  lineHeight: '0.7rem',
  uspFontSize: '0.7rem',
  uspFontWeight: '800',
  uspFontColor: 'var(--white)',
  iconColor: 'var(--white)',
  borderTop: '1px solid rgba(255, 255, 255, 0.22)',
  samblaStyleUSPDisplay: 'none',
  prefixIconDisplay: 'none',
  suffixIconDisplay: 'block',
};
