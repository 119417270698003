import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { LocationProvider } from '@reach/router';
import styled, { css } from 'styled-components';
import devices from '../../../../../js/styles/Devices';

import ExtraFormFunctionalityWrapper from '../../ExtraFormFunctionalityWrapper';
import Form from '../../../../common/Form';
import Disclaimer from '../../../../utils/Disclaimer/Disclaimer';

const Wrapper = styled.div`
  ${(props) => props.leadForm && css`
    margin: 1rem auto;
    max-width: 55rem;
  `}
`;

const FormBlockWrap = styled.div`
  && {
    position: relative;
    text-align: left;
    padding-top: 0;
    border-radius: 1rem;
    background-color: var(--form-background);
    margin: 0 auto;

    @media ${devices.upFromTablet} {
      width: ${(props) => props.frameWidth};
    }

    ${(props) => props.type === 'loanCalculator' && css`
      max-width: 46rem;
    `}
  }
`;

const FormFrame = styled.div`
  && {
    @media ${devices.upFromTablet} {
      width: ${(props) => props.frameWidth};
    }

    @media ${devices.downFromTablet} {
      width: 100%;
    }

    ${(props) => props.type === 'loanCalculator' && css`

      @media ${devices.smallDesktop} {
        width: 30rem;
      }

      @media ${devices.upFromTablet} {
        width: 46rem;
      }
    `}
  }
`;

const FormBlock = ({
  show,
  disclaimer,
  forwardRef,
  exampleLoan,
  title,
  partnerForm,
  prefilledApplicationForm,
  prefilledValues,
  subtitle,
  type,
  ...rest
}) => {
  if (!show) return null;
  const leadForm = type[0] === 'lead' || type[0] === 'loanCalculator';

  return (
    <Wrapper
      leadForm={leadForm}
    >
      <FormBlockWrap
        ref={forwardRef}
        id="form"
        frameWidth={leadForm ? '55rem' : '70rem'}
        type={type}
      >
        <FormFrame
          frameWidth={leadForm ? '55rem' : '70rem'}
          type={type}
        >
          <ExtraFormFunctionalityWrapper>
            <LocationProvider>
              {(context) => (
                <>
                  <Form
                    key="form-block"
                    exampleLoan={exampleLoan}
                    title={title}
                    subtitle={subtitle}
                    location={context.location}
                    partnerForm={partnerForm}
                    prefilledApplicationForm={prefilledApplicationForm}
                    prefilledValues={prefilledValues}
                    type={type[0]}
                    {...rest}
                  />
                </>
              )}
            </LocationProvider>
          </ExtraFormFunctionalityWrapper>
        </FormFrame>
      </FormBlockWrap>
      {leadForm && (
        <Disclaimer
          margin={prefilledApplicationForm ? '1rem auto' : 'auto'}
        />
      )}
    </Wrapper>
  );
};

FormBlock.propTypes = {
  show: PropTypes.bool,
  disclaimer: PropTypes.string,
  inputSource: PropTypes.string,
  advisaSource: PropTypes.string,
  forwardRef: PropTypes.shape(),
  exampleLoan: PropTypes.shape(),
  title: PropTypes.string,
  partnerForm: PropTypes.bool,
  prefilledApplicationForm: PropTypes.bool,
  prefilledValues: PropTypes.shape(),
  subtitle: PropTypes.string,
  type: PropTypes.arrayOf(PropTypes.string),
};

FormBlock.defaultProps = {
  show: true,
  type: [''],
  disclaimer: null,
  inputSource: '',
  advisaSource: '',
  forwardRef: null,
  exampleLoan: null,
  title: null,
  partnerForm: false,
  prefilledApplicationForm: false,
  prefilledValues: {},
  subtitle: null,
};

export default FormBlock;

export const formBlockQuery = graphql`
  fragment formBlockFields on Form {
    show
    type
    title
    subtitle
    type
    inputSource
    advisaSource
  }
`;
