export default {
  contentWrapperBgOverlay: 'linear-gradient(0deg, #FFF 15%, rgba(29, 39, 49, 0.00) 15%)',
  mobileTitleColor: '#432400',
  buttonRadius: '8px',
  secondaryButtonBorder: '1px solid #432400',
  primaryButtonBg: '#FF7000',
  buttonTextColor: '#432400',
  secondaryButtonMobileBg: 'transparent',
  trustBoxBg: '#FFF2E8',
};
