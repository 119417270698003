export default {
  authorBackground: 'transparent',
  childWidth: '100%',
  alignment: 'center',
  flexAlign: 'center',
  marginLeft: '0',
  size: '7em',
  linkColor: 'var(--orange)',
  clipPath: 'circle(50% at 50% 50%)',
  backgroundContainerDisplay: 'none',
  dateColor: 'var(--base-brown)',
};
