import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import styled, { css } from 'styled-components';

import { brand } from '../../../js/localizations/current-locale';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import { getFontFamily } from '../../../js/utils/sectionHelper';
import ImageBlock from '../ImageBlock';
import devices from '../../../js/styles/Devices';
import style from './style';

const ImageWrap = styled.div`
  && {
    margin: ${style.imageWrapper};
    display: ${style.imgWrapDisplay};
    justify-content: ${style.imgWrapJustifyCont};

    ${(props) => props.aboveFold && css`
      margin: ${style.aboveFoldImageWrapper};
    `}
  }
`;

const Image = styled(ImageBlock)`
  && {
    display: flex;
    width: ${style.imageWidth};
    height: ${style.imageHeight};
    margin: ${style.marginStepImage};

    img{
      object-fit: contain !important;
    }

    @media ${devices.downFromSmallTablet} {
      margin: ${style.mobileImageMargin}
    }

    ${(props) => props.aboveFold && css`
      width: ${style.imageSize};
      height: ${style.imageSize};
      display: block;
      margin: ${style.imageMargin};
    `}
  }
`;

const TextWrapper = styled.div`
  && {
    p {
      font-size: var(--h4-mobile-font-size);
      font-family:  var(--font-family);
      line-height: ${style.textWrapperLineHeight};
    }

    a {
      color: var(--link-color);
    }

    h3 {
      margin-top: inherit;
    }

    h4 {
      padding: none;
      font-size: var(--h4-mobile-font-size);
      font-family:  var(--font-family);
      font-weight: var(--has-font-weight);
    }

    h6 {
      text-align: ${style.textPosition};
    }

    ${(props) => props.aboveFold && css`
      padding-left: 1.5rem;
      padding: ${style.textWrapperPadding};
      font-family:  var(--font-family);
      width: ${style.textWrapperWidth};

      p {
        font-size: var(--h4-mobile-font-size);
        line-height: 1.5rem;
        font-weight: var(--has-font-weight);
      }

      h4 {
        width: ${style.lastStepWidth};
        padding: none;
        margin-left: ${style.lastStepMarginLeft};
        line-height: ${style.lastStepLineHeight};
        font-weight: var(--has-font-weight);
        font-size: var(--h4-mobile-font-size);
      }
    `}
  }
`;

const Title = styled.h5`
  && {
    font-weight: var(--has-no-font-weight);
    letter-spacing: var(--has-letter-spacing);
    margin-bottom: 1.2rem;
    font-size: var(--h1-mobile-font-size);

    ${(props) => props.aboveFold && css`
      margin-bottom: 1rem;
      border-bottom: 1px solid var(--light-grey);
      font-weight: 900;
      font-size: var(--h1-mobile-font-size);
      padding: 1rem;
      color: var(--white);
      font-family: var(--font-family);
      line-height: 1;
      letter-spacing: var(--has-letter-spacing);
    `}

    @media ${devices.downFromMobile} {
      padding: ${style.titlePadding};
    }
  }
`;

const StepWrap = styled.div`
  && {
    display: ${(props) => (props.aboveFold ? 'column' : 'flex')};
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: ${style.flexDirection};

    @media ${devices.downFromDesktop} {
      margin-top: 1rem;
      padding: 1rem 0;
      flex-direction: ${style.downFromDesktopFlexDirection};
      background-color: ${style.mobileStepByStepBackground};
    }

    @media ${devices.downFromTablet} {
      flex-direction: ${style.tabletFlexDirection};
    }

    @media ${devices.downFromSmallTablet} {
      flex-direction: ${(props) => props.stepFlexDirection};
    }

    @media ${devices.downFromMobile} {
      flex-direction: ${style.mobileFlexDirection};
    }

    @media ${devices.upFromDesktop} {
      width: ${style.widthStepWrap};
    }
  }
`;

const Step = styled.div`
  && {
    display: ${(props) => (props.aboveFold ? 'flex' : 'column')};
    position: relative;
    text-align: ${(props) => props.textAlign};;
    max-width: ${style.stepByStepMaxWidth};
    margin: ${(props) => (props.aboveFold ? style.aboveFoldStepMargin : style.stepMargin)};

    @media ${devices.upFromTablet} {
      flex: 1 1 0;
    }

    p, h4 {
      text-align: center;
      font-weight: ${style.stepFontWeight};
      color: ${style.pColor};
    }

    p {
      font-size: var(--text-font-size);
    }

    h4 {
      font-size: var(--h4-mobile-font-size);
      font-weight: var(--has-font-weight);
    }

    &:last-child {
      svg {
        display: none;
      }
    }

    ${(props) => props.aboveFold && css`
    border-bottom: 1px solid var(--light-grey);
    width: 100%;

      p, h4 {
        text-align: ${style.aboveFoldTextAlign};
        font-family: var(--font-family);
        font-weight: var(--has-font-weight);
      }

    `}
  }
`;

const StepByStepGuideBlock = ({
  title,
  aboveFold,
  steps,
  className,
  small,
  fontFamily,
  isSection,
  stepFlexDirection,
}) => {
  if (!steps) return null;

  return (
    <div
      className={classNames(
        className,
      )}
    >
      {title && (
        <Title
          aboveFold={aboveFold}
        >
          {title}
        </Title>
      )}

      <StepWrap
        aboveFold={aboveFold}
        paddingLP={(brand === 'lanaPengarna') ? '20%' : ''}
        spaceBetween={(!small) ? 'space-between' : ''}
        stepFlexDirection={stepFlexDirection}
      >
        {steps.map((step, i) => (
          <Step
            key={`step-${i}`}
            textAlign={isSection ? 'center' : ''}
            aboveFold={aboveFold}
          >
            <ImageWrap aboveFold={aboveFold}>
              <Image
                aboveFold={aboveFold}
                imageObject={step.media}
              />
            </ImageWrap>

            <TextWrapper
              aboveFold={aboveFold}
              fontFamily={getFontFamily(fontFamily)}
              fontSize={(brand === 'sambla' || brand === 'lanaPengarna') ? '1rem' : '1.3rem'}
              dangerouslySetInnerHTML={createMarkup(step.text)}
            />
          </Step>
        ))}
      </StepWrap>
    </div>
  );
};

StepByStepGuideBlock.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    image: ImageBlock.propTypes.imageObject,
  })),
  className: PropTypes.string,
  small: PropTypes.bool,
  fontFamily: PropTypes.string,
  isSection: PropTypes.bool,
  stepFlexDirection: PropTypes.string,
  aboveFold: PropTypes.bool,
};

StepByStepGuideBlock.defaultProps = {
  title: null,
  aboveFold: false,
  steps: [],
  className: '',
  small: false,
  fontFamily: '',
  isSection: false,
  stepFlexDirection: '',
};

export default StepByStepGuideBlock;

export const stepByStepGuideBlockQuery = graphql`
  fragment stepByStepGuideBlockFields on StepByStepGuide {
    title
    steps {
      text
      media {
        ...imageBlockFieldsWithoutImage
        image {
          node {
            gatsbyImage(width: 800, quality: 90, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
