export default {
  fontSize: '1rem',
  fontWeight: '400',
  titleFontSize: '1.5rem',
  letterSpacing: 'normal',
  gridTemplateMobile: '1fr',
  gridTemplateDesktop: '1fr 1fr 1fr',
  gridMargin: 'auto',
  gridMaxWidt: '1200px',
};
