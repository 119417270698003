export default {
  topHeadlineMargin: '2rem',
  topPaddingDesktop: 'none',
  topPaddingMobile: '2em',
  sectionContPaddingRight: '2rem',
  liFontSize: '1.3rem',
  background: 'var(--sambla-red)',
  color: 'var(--white)',
  textColor: 'var(--white)',
  bulletColor: '#000',
  tableDisplay: 'block',
  tablePadding: '5px',
  tableAlign: 'center',
  tableHeaderColor: '#fff',
  tableHeaderBackgroundColor: '#df202d',
  tableRowColorOne: '#eee',
};
