import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';

import SectionWrapper from '../../common/SectionWrapper';
import StepByStepGuideBlock from '../../blocks/StepByStepGuideBlock';
import HeroBlock from '../../blocks/HeroBlock';
import FormBlock from '../../blocks/FormBlock';
import ImageBlock from '../../blocks/ImageBlock';
import USPBlock from '../../blocks/USPBlock';
import Reapply from '../../../js/forms/GenericComponents/Reapply/Reapply';
import { useOptions } from '../../../context/options';

import useDevice from '../../../js/utils/use-device';
import devices from '../../../js/styles/Devices';
import style from './style';
import createConfig from './config';
import dynamicEvaluation from '../../utils/dynamic-config-checker';
import { brand } from '../../../js/localizations/current-locale';

const Text = styled.div`
  && {
    font-size: var(--text-font-size);
    font-family: var(--font-family);
    display: block;
    position: relative;
    z-index: 1;

    p {
      font-size: var(--text-font-size);
    }

    a {
      color: var(--link-color);
      margin: 1rem 0;
    }

    h1 {
      font-family: var(--title-font);
      font-size: var(--h1-desktop-font-size);
      font-weight: var(--has-font-weight);
      letter-spacing: var(--has-letter-spacing);
    }

    h2, h2.span {
      font-family: var(--font-family);
      font-size: var(-h2-font-size);
      font-weight: var(--has-font-weight);
      letter-spacing: var(--has-letter-spacing);
    }

    @media ${devices.upFromTablet} {
      margin: ${style.textMargin};
    }

    @media ${devices.downFromTablet} {
      h1 {
        font-size: var(--h1-mobile-font-size);
      }
    }
`;

const TextMobile = styled.div`
  color: ${style.textMobileColor};
  padding-bottom: 3rem;

  @media ${devices.upFromMobile} {
    display: none;
  }
`;

const Title = styled.h1`
  font-size: var(--h4-desktop-font-size);
  @media ${devices.downFromMobile} {
    display: none;
  }
`;

const HeadTitle = styled.h1`
  color: ${style.textMobileColor};
  font-weight: var(--has-no-font-weight);

  @media ${devices.upFromMobile} {
    display: none;
  }
`;

const HeroSpacer = styled.div`
  && {
    width: 100%;
    height: auto;
    aspect-ratio: ${style.desktopAspectRatio};
    margin-bottom: 1.5rem;

    @media ${devices.downFromLargeTablet} {
      aspect-ratio: ${style.largeTabletAspectRatio};
    }

    @media ${devices.downFromSmallTablet} {
      aspect-ratio: ${style.tabletAspectRatio};
    }

    @media ${devices.downFromMobile} {
      display: none;
    }
  }
`;

const LayoutWrapper = styled.div`
  && {
    padding: ${style.layoutPadding};
    margin: 0 auto;
    position: relative;
    display: flex;
    display: -ms-flexbox;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 1rem;
    max-width: ${style.maxWrapperWidth};

    @media ${devices.downFromDesktop} {
      flex-direction: column;
      margin: 0 auto;
      padding: 2rem 1rem;
      max-width: ${style.maxWrapperWidthDownFromDesktop};
    }

    @media ${devices.upFromLargeDesktop} {
      max-width: ${style.maxWrapperWidthUpFromLargeDesktop};
    }
  }
`;

const BackgroundLayer = styled.div`
  && {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    min-height: 100%;
    overflow: hidden;
    background: ${(props) => props.backgroundColor};
    background-size: cover;
    background-image: ${(props) => props.backgroundImage};

    @media ${devices.downFromTablet}{
      background-image: ${(props) => props.mobileBackgroundImage};
    }
  }
`;

const PositionWrapper = styled.div`
  && {
    position: ${(props) => props.position};
  }
`;

const HeroLayer = styled.div`
  && {
    position: relative;
    margin-bottom: 1rem;

    @media ${devices.downFromMobile} {
      > div {
        position: initial;
        padding: 1rem 0;

        > div {
          position: unset!important;
        }
      }

      img {
        padding-top: 2rem;
      }
    }

    img {
      padding-top: 2rem;
    }

    h1 {
      padding-left: ${style.desktopTitlePadding};
      position: absolute;
      bottom: 0;
      left: 5%;
      /* font-size: var(--h1-desktop-font-size); */
      color: var(--white);
    }

    @media ${devices.downFromTablet} {
      h1 {
        position: relative;
        left: 0;
        margin: 0;
        background: var(--white);
        font-size: var(--h1-mobile-font-size);
        line-height: 1;
        color: var(--black);
        padding: 0.4rem 0.5rem 0.7rem;
        text-align: center;
      }
    }
  }
`;

const CenteredFormTitle = styled.div`
  && {
    text-align: center;
    color: ${(props) => props.titleColor};
    position: relative;
    z-index: 1;
    padding-top: ${style.topTitlePadding};
    text-shadow: ${style.textShadow};

    h1 {
      font-weight: var(--has-font-weight);
      font-family: var(--title-font);
    }

    h2 {
      font-family: var(--font-family);
      font-weight: var(--has-font-weight);
    }

    @media ${devices.downFromTablet} {
      padding: 2.5rem 1rem 0;

      h1 {
        font-size: var(--h1-mobile-font-size);
      }
    }
  }
`;

const CenteredForSubtitle = styled.h2`
  && {
    font-family: var(--font-family);
    font-size: var(--h2-desktop-font-size);
    font-weight: var(--has-font-weight);
    line-height: 1.25;
    color: ${(props) => props.titleColor};

    @media ${devices.downFromTablet} {
      font-weight: var(--has-no-font-weight);
    }
  }
  `;

const FlexCol = styled.div`
  && {
    flex: 1;
    -ms-flex: 1;
    flex-basis: ${(props) => props.flexSize}!important;
    max-height: fit-content;
    margin-top: ${(props) => (props.float ? '-24rem' : 'none')};

    @media ${devices.downFromDesktop} {
      flex: 1 1 auto;
      margin-top: 0;
    }
  }
`;

const Foreground = styled.img`
  && {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 50rem;
    width: auto;
    transform: ${style.foregroundTranslate};

    @media ${devices.downFromDesktop} {
      display: none;
    }
  }
`;

const MobileForeground = styled.img`
  && {
    margin: 0 auto;
    height: ${style.mobileForegroundHeight};
    width: ${style.mobileForegroundWidth};;
    margin-bottom: -7px;
    z-index: 0;
    transform: ${style.foregroundMobileTranslate};

    @media ${devices.upFromTablet} {
      display: none;
    }

    @media ${devices.mobile} {
      display: ${style.mobileForegroundDisplay};
    }
  }
`;

const Campaign = styled.div`
  && {
    margin: 0 auto;

    @media ${devices.downFromDesktop} {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2rem;
    }
  }
`;

const AboveFoldWithFormSection = ({ acf }) => {
  const { isLargeMobile, isTabletAndUp } = useDevice();

  const {
    optionCampaigns,
  } = useOptions();
  const campaign = optionCampaigns?.campaigns?.find((c) => c.name === acf.campaignName);
  const config = createConfig(acf, style, campaign);
  const isRahalaitos = brand === 'rahalaitos';
  const isOmalaina = brand === 'omalaina';

  const slideComponents = {
    FormBlock,
    Text,
    MobileForeground,
    StepByStepGuideBlock,
    Campaign,
    USPBlock,
    Reapply,
    TextMobile,
    HeadTitle,
  };

  return (
    <SectionWrapper
      settings={acf.sectionSettings}
      noContainer
      disablePadding
    >
      <PositionWrapper position={config.general.wrapperPosition}>
        <BackgroundLayer
          backgroundColor={config.general.hero.backgroundColor}
          backgroundImage={config.general.hero.fullBackgroundImage}
          mobileBackgroundImage={config.general.hero.fullMobileBackgroundImage}
        >

          {config.general.hero
            && (
            <HeroLayer>
              {isLargeMobile
                && <ImageBlock imageObject={config.general.hero.mobileBackgroundImage} />}
              { isTabletAndUp && <ImageBlock imageObject={config.general.hero.backgroundImage} />}
              {config.general.hero.title
                && <Title dangerouslySetInnerHTML={createMarkup(config.general.hero.title)} />}
              {isLargeMobile && (
                config.general.hero.trustpilotWidget
              )}
            </HeroLayer>
            )}
          {config.general.hero.foregroundImage
            && <Foreground src={config.general.hero.foregroundImage} />}
          {config.general.hero.mobileForegroundImage
          && <MobileForeground src={config.general.hero.mobileForegroundImage} />}
        </BackgroundLayer>

        {config.general.hero.backgroundImage
          && <HeroSpacer />}

        {(isRahalaitos || isOmalaina)
          && (
          <CenteredFormTitle
            titleColor={acf.sectionSettings.titleColor}
          >
            <h1 dangerouslySetInnerHTML={createMarkup(acf.desktopHero.title)} />
            <CenteredForSubtitle
              dangerouslySetInnerHTML={createMarkup(acf.desktopHero.subtitle)}
              titleColor={acf.sectionSettings.titleColor}
            />
            <p dangerouslySetInnerHTML={createMarkup(acf.pitchText)} />
          </CenteredFormTitle>
          )}

        <LayoutWrapper>
          {config.columns.map((col, key) => (
            <FlexCol flexSize={col.flexSize || '100%'} float={col.float} key={key}>
              {col.components.map((item, itemKey) => {
                const FormComponent = slideComponents[item.component];

                return (
                  <>
                    {dynamicEvaluation(item.props.showComponent, { campaign })
                        && <FormComponent key={`${item.component}_${itemKey}`} {...item.acf} {...item.props} />}
                  </>
                );
              })}
            </FlexCol>

          ),
          )}
        </LayoutWrapper>
      </PositionWrapper>
    </SectionWrapper>
  );
};

AboveFoldWithFormSection.propTypes = {
  acf: PropTypes.shape({
    mobileHero: PropTypes.shape(HeroBlock.propTypes).isRequired,
    desktopHero: PropTypes.shape(HeroBlock.propTypes).isRequired,
    pitchText: PropTypes.string,
    pitchTextMobile: PropTypes.string,
    guideSteps: PropTypes.shape(StepByStepGuideBlock.propTypes),
    sectionSettings: SectionWrapper.propTypes.settings,
    form: PropTypes.shape(FormBlock.propTypes).isRequired,
    fadingBackground: PropTypes.bool,
    campaignName: PropTypes.string,
    stepper: PropTypes.shape(USPBlock.propTypes.items),
  }).isRequired,
};

export default AboveFoldWithFormSection;
