export default {
  questionColor: 'var(--base-brown)',
  faqQuestionPadding: '1rem',
  faqIconColor: '#FF3926',
  borderRadius: '3px',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  titleSizeDownLaptop: '1.8rem',
  summaryPadding: '0',
  margin: '0',
  boxShadow: 'none',
  questionPadding: '1rem',
  questionJustification: 'space-between',
  answerPadding: '0.5rem 1rem 1rem',
  bulletColor: '#000',
  order: '1',
  FAQAnswerMargin: '0',
  fontSize: '2.3rem',
  letterSpacing: 'var(--has-no-letter-spacing)',
  expandButtonBorderRadius: '28px',
  borderWidth: '2px',
  borderColor: 'var(--black)',
  expandButtonMargin: 'auto',
  paddingTop: '-2rem',
  svgTransform: '0deg',
  originalSvgTransForm: '-90deg',
  buttonBackground: 'var(--transparent)',
  borderStyle: 'solid',
  columnRadius: '1rem',
  marginBottom: '1rem',
  h1FontWeight: 'var(--has-font-weight)',
  titleFontSizeMobile: '1.8rem',
  answerFontSize: '1rem',
};
