export default {
  flexDirection: 'column',
  tabletFlexDirection: 'row',
  mobileFlexDirection: 'column',
  imageWidth: '3.5rem',
  imageHeight: '3.5rem',
  imageWrapper: '0 auto 0.2rem',
  mobileImageMargin: '0 auto 1rem',
  stepFontWeight: '800',
  stepMargin: '0 auto 1rem',
  stepByStepMaxWidth: '100%',
  pColor: 'var(--white)',
  downFromDesktopFlexDirection: 'row',
  imageSize: '5rem',
  marginStepImage: '0 auto 1rem',
};
