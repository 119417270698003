export default {
  boxShadow: 'none',
  mobileMenuButtonColor: 'black',
  menuMarginTopMobile: '0.7rem',
  textBgColor: 'var(--base-brown)',
  buttonSvgWidthHeight: '1.5rem',
  backgroundColor: 'var(--white)',
  linkColor: 'var(--base-brown)',
  fontFamily: 'var(--fontFamily)',
  overlayBackgroundColor: 'var(--white)',
  activeLinkColor: 'var(--orange)',
  loginButtonBackgroundColor: 'var(--orange)',
  loginButtonTextColor: 'var(--base-brown)',
  loginButtonBorderRadius: '0.5rem',
  loginIconColor: 'var(--base-brown)',
  phoneNumberIconColor: 'var(--base-brown)',
};
