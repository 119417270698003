export default {
  h1FontFamily: 'Poppins',
  textBlockMobileDisplay: 'block',
  textBlockMobileFontWeight: 'bold',
  textBlockMobileFontSizeTitle: '2rem',
  textBlockDesktopFontWeight: 'bold',
  textBlockDesktopFontSizeTitle: '2rem',
  gridGap: '0 3rem',
  campaignWrapperBackground: 'var(--white)',
  campaignTextColor: 'var(--black)',
  formBlockColor: 'var(--white)',
};
